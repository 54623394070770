import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./popnav.scss";

/**
 * Summary - This Popnav component groups all the navigation links + handle responsive
 * This component is called in Burger.jsx component
 * @param {boolean} open - controls the opening and closing of the responsive navigation menu
 * @param {useState} setOpen - set the state of "open"
 * @returns
 */

const Popnav = ({ open, setOpen }) => {
  const [userLogged, setUserLogged] = useState(false);
  const [checkLog, setCheckLog] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("currentUser")) {
      setUserLogged("");
    } else if (localStorage.getItem("currentUser") && !checkLog) {
      let user = JSON.parse(localStorage.getItem("currentUser"));
      setUserLogged(user);
      setCheckLog(true);
    }
  }, [userLogged, checkLog]);

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <ul open={open} className={`popnavUl ${open ? "open" : "close"}`}>
      <li className="mobileOnly">
        <Link to="/" className="navlinkStyle " onClick={() => handleClick()}>
          HOME
        </Link>
      </li>
      <li>
        <Link
          to="/current-mission"
          className="navlinkStyle "
          onClick={() => handleClick()}
        >
          CURRENT MISSION
        </Link>
      </li>
      <li>
        <Link
          to="/news-events"
          className="navlinkStyle "
          onClick={() => handleClick()}
        >
          NEWS & EVENTS
        </Link>
      </li>
      <li>
        <Link
          to="/history"
          className="navlinkStyle "
          onClick={() => handleClick()}
        >
          HISTORY
        </Link>
      </li>
      <li className="last">
        <Link
          to="/contact"
          className="navlinkStyle"
          onClick={() => handleClick()}
        >
          CONTACT
          <span></span>
        </Link>
      </li>
    </ul>
  );
};

export default Popnav;
