import React from "react";
import julianJ from "../../assets/img/julian-jones.jpg";
import johnO from "../../assets/img/johnnyo.jpeg";

const LocalGov = () => {
  const list = [
    {
      img: julianJ,
      title: "Julian E. Jones Jr. - Baltimore County Councilman - 4th District",
      link: "https://countycouncil.baltimorecountymd.gov/countycouncil/districts/district4/index.html",
    },
    {
      img: johnO,
      title: "Johnny Olszewski - Baltimore County Executive",
      link: "https://msa.maryland.gov/msa/mdmanual/36loc/bco/html/msa14580.html",
    },
  ];
  return (
    <section className="localgov">
      <h3>Local Government</h3>
      <div className="list">
        {list.map((item) => (
          <div className="card">
            <img src={item.img} alt="" />
            <p>{item.title}</p>
            <a href={item.link} target="_blank" rel="noreferrer">
              <button>Contact Information</button>
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LocalGov;
