import React from "react";
import RenderPdf from "../RenderPdf";
import article from "../../assets/documents/villagerArticle.pdf";
import { Link } from "react-router-dom";
import "../../styles/eventsnews.scss";

const VillagerArticle = () => {
  return (
    <div className="villager-article">
      <div className="button-box">
        <button>
          <Link to="/news-events">Back</Link>
        </button>
      </div>

      <RenderPdf doc={article} />
    </div>
  );
};

export default VillagerArticle;
