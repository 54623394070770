import React from "react";
import address from "../../assets/img/location.png";
import email from "../../assets/img/mail.png";
import img from "../../assets/img/post-office.jpeg";
import phone from "../../assets/img/telephone.png";
import "../../styles/contact.scss";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <img src={img} alt="Boring postoffice" className="illustration" />
      <div className="text-content">
        <h1>Contact Us</h1>
        <p>
          <img src={email} alt="" className="icons" />
          Boringcommunitymd@gmail.com
        </p>
        <p>
          <img src={phone} alt="" className="icons" />
          (443) 955-2011
        </p>
        <p>
          <img src={address} alt="" className="icons" />
          P.O. Box 97 14707 Old Hanover Rd Boring, MD 21020
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
