import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/news01.png";
import img3 from "../../assets/img/train.jpg";
import img2 from "../../assets/img/villagerArticle.png";
import nobles from "../../assets/img/nobles.png";
import rona1 from "../../assets/img/rona1.png";
import rona2 from "../../assets/img/rona2.png";

const News = () => {
  const [drop, setDrop] = useState(true);
  const newsList = [
    {
      img: img3,
      title: "Uncovering the Charm of Boring ",
      date: "11/29/2023",
      link: "/news-events/uncovering-the-charm-of-boring-article",
    },
    {
      img: img1,
      title: "Townhall Meeting Video",
      date: "03/01/2020",
      link: "https://www.youtube.com/watch?v=BX66dVcPwIw",
      linkTitle: "Watch video",
    },
    {
      img: img2,
      title: "Villager Article",
      date: "03/01/2020",
      link: "/news-events/villager-article",
    },
    {
      img: nobles,
      title: "We'd like to stay boring - Baltimore County residents worry zoning process has shut them out of fire hall decision.",
      date: "7/8/2020",
      link: "https://www.baltimoresun.com/2020/07/08/wed-like-to-stay-boring-baltimore-county-residents-worry-zoning-process-has-shut-them-out-of-fire-hall-decision/",
    },
    {
      img: rona1,
      title: "Boring just wanted to keep its bingo hall. Now it’s got a mess.",
      date: "6/6/2024",
      link: "https://www.thebaltimorebanner.com/politics-power/local-government/boring-julian-jones-3PZL6EQU7BFG3LIHQO6YSBV4WA/",
    },
    {
      img: rona2,
      title: "In Baltimore County, it only takes one council member to rezone land for more (or less) development",
      date: "8/23/2024",
      link: "https://www.thebaltimorebanner.com/politics-power/local-government/baltimore-county-council-czmp-izzy-patoka-AE4ZCNNNLFHGNPLSPB5SHH5GGQ/",
    },
  ];

  return (
    <section className="news-container">
      <div className="banner">
        <h1>News</h1>
        <button
          onClick={() => {
            setDrop(!drop);
          }}
        >
          {drop ? "Close" : "Open"} news list
        </button>
      </div>
      {drop && (
        <>
          <ul className="news-list">
            {newsList.length
              ? newsList.map((item, itemKey) => (
                  <li key={itemKey} className="card">
                    <img src={item.img} alt="" />
                    <p>{dayjs(item.date).format("MM/DD/YYYY")}</p>
                    <h2>{item.title}</h2>
                    <Link to={item.link} target="_blank">
                      {item.linkTitle ? item.linkTitle : "Read More"}
                    </Link>
                  </li>
                ))
              : "NO NEWS"}
          </ul>
        </>
      )}
    </section>
  );
};

export default News;
