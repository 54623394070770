import React from "react";
import historyDoc from "../../assets/documents/historyBoring.pdf";
import "../../styles/history.scss";
import RenderPdf from "../RenderPdf";

const HistoryPage = () => {
  return (
    <div className="hist-page">
      <RenderPdf doc={historyDoc} />
    </div>
  );
};

export default HistoryPage;
