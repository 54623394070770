import React, { useRef } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/img/train.jpg";

const Landing = () => {
  const scrollTo = useRef(null);

  return (
    <>
      <section className="landing">
        <span className="blur"></span>
        <div className="content-box">
          <div className="title">
            <h1>BORING</h1>
            <h1>COMMUNITY</h1>
            <h1>ASSOCIATION</h1>
          </div>
          <div className="banner">
            <div class="thick-arrow">
              <span className="main-arrow"></span>
              <span className="stroke-arrow"></span>
            </div>
            <Link to="/current-mission">
              <button>
                <h2>TAKE ACTION</h2>
              </button>
            </Link>
            <div class="thick-arrow">
              <span className="main-arrow"></span>
              <span className="stroke-arrow"></span>
            </div>
          </div>

          <div
            className="down-btn"
            data-aos="fade"
            data-aos-delay="2500"
            onClick={() => {
              scrollTo.current.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <p>Scroll down</p>
            <span className="arrow">
              <span className="straight"></span>
              <span className="left"></span>
              <span className="right"></span>
            </span>
          </div>
        </div>
      </section>
      <section className="about-us" ref={scrollTo}>
        <div className="content">
          <div>
            <h2>About Boring Community Association</h2>
            <p>
              The Boring Community Association is a dedicated group of people
              who care deeply about our town. We are involved in various
              community events and interests, including organizing the beloved
              Santa in Boring celebration every year.
            </p>
            <Link to="/history">
              <button>Learn about Boring history</button>
            </Link>
          </div>
        </div>
        <div className="img">
          <img src={image} alt="" />
          <span className="gradient"></span>
        </div>
      </section>
    </>
  );
};

export default Landing;
