import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/santo1.jpeg";
import img2 from "../../assets/img/santo2.jpeg";
import img3 from "../../assets/img/santo3.jpeg";

const CurrentMission = () => {
  return (
    <section className="current-mission">
      <div className="pictures">
        <img src={img1} alt="" id="img1" />
        <div className="side">
          <img src={img2} alt="" id="img2" />
          <img src={img3} alt="" id="img3" />
        </div>
      </div>
      <div className="content">
        <h2>Current mission</h2>
        <p>
          Our mission is to rally together and take action to prevent the
          rezoning of the firehall to Manufacturing Light (ML). We need your
          support to ensure that Boring remains a safe, thriving, and
          family-friendly community.
        </p>
        <Link to="/current-mission">
          <button>Learn more</button>
        </Link>
      </div>
    </section>
  );
};

export default CurrentMission;
