import { Route, Routes } from "react-router-dom";
import ContactPage from "./components/contact/ContactPage";
import CasePage from "./components/current-mission/CasePage";
import CurrentMissionPage from "./components/current-mission/CurrentMissionPage";
import Footer from "./components/Footer";
import HistoryPage from "./components/history/HistoryPage";
import HomePage from "./components/home/HomePage";
import Navbar from "./components/navbar/Navbar";
import CharmOfBoringArticle from "./components/news-events/CharmOfBoringArticle";
import NewsEventsPage from "./components/news-events/NewsEventsPage";
import VillagerArticle from "./components/news-events/VillagerArticle";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/current-mission" element={<CurrentMissionPage />} />
        <Route exact path="/current-mission/case" element={<CasePage />} />
        <Route exact path="/news-events" element={<NewsEventsPage />} />
        <Route
          exact
          path="/news-events/villager-article"
          element={<VillagerArticle />}
        />
        <Route
          exact
          path="/news-events/uncovering-the-charm-of-boring-article"
          element={<CharmOfBoringArticle />}
        />
        <Route exact path="/history" element={<HistoryPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
