import React from "react";
import { Link } from "react-router-dom";
import facebook from "../assets/img/facebook.png";
import "../styles/footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="left-side">
          <h2>Boring Commutity Association</h2>
          <Link to="/current-mission">
            <button>TAKE ACTION NOW</button>
          </Link>
        </div>
        <div className="right-side">
          <Link to="/contact">Contact</Link>
          <Link
            to="https://www.facebook.com/p/Boring-Community-Association-Maryland-21020-100064559055185/?_rdr"
            target="_blank"
          >
            <img className="social-media" src={facebook} alt="" />
          </Link>
        </div>
      </div>
      <p className="boringtech">
        Made with ❤️ by{" "}
        <Link to="https://www.boring.tech/" target="_blank">
          Boring Technologies
        </Link>
      </p>
      <p className="credits">
        Credits: Facebook icon made by{" "}
        <a
          href="https://www.flaticon.com/free-icons/facebook"
          title="facebook icons"
        >
          Freepik - Flaticon
        </a>
      </p>
    </div>
  );
};

export default Footer;
