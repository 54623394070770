import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <section className="contact">
      <div className="content">
        <h2>Join Us Today</h2>
        <p>
          Join the Boring Community Association today and help us preserve the
          unique charm and safety of our town.
        </p>
        <Link to="/contact">
          <button>Contact Us</button>
        </Link>
      </div>
    </section>
  );
};

export default Contact;
