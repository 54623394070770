import React from "react";
import img1 from "../../assets/img/poster01.jpg";
import img2 from "../../assets/img/poster02.jpg";

const Gallery = () => {
  const logos = [
    { name: "aetna", img: img1 },
    { name: "carefirst", img: img2 },
  ];

  return (
    <section className="gallery">
      <h3>Gallery</h3>
      <div className="img-container">
        {logos.map((logo, logoIndex) => (
          <img src={logo.img} alt={logo.name} key={logoIndex} />
        ))}
      </div>
    </section>
  );
};

export default Gallery;
