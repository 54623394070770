import { useRef } from "react";
import { Link } from "react-router-dom";

const MissionLanding = () => {
  const scrollTo = useRef(null);

  return (
    <section className="about-us" ref={scrollTo}>
      <div className="content">
        <h2>The Case About Boring</h2>
        <p>
          The Boring community is facing a critical challenge as a local
          construction business seeks to rezone the old firehall to
          Manufacturing Light (ML). This change would bring industrial
          activities and heavy vehicles to our peaceful village, disrupting the
          character of our town. We stand united against the rezoning to protect
          the safety and future of Boring.
        </p>
        <p>Boring history</p>
        <Link to="/current-mission/case">
          <button>Learn more</button>
        </Link>
      </div>
      <img src="" alt="" />
    </section>
  );
};

export default MissionLanding;
