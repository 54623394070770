import React from "react";
import News from "./News";
import Events from "./Events";
import "../../styles/eventsnews.scss";

const NewsEventsPage = () => {
  return (
    <div className="news-events">
      <Events />
      <News />
    </div>
  );
};

export default NewsEventsPage;
