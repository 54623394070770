import React from "react";
import "../../styles/home.scss";
import CurrentMission from "./CurrentMission";
import Landing from "./Landing";
import Contact from "./Contact";

const HomePage = () => {
  return (
    <div className="home-page">
      <Landing />
      <CurrentMission />
      <Contact/>
    </div>
  );
};

export default HomePage;
