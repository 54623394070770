import React from "react";
import caseDoc from "../../assets/documents/caseDoc.pdf";
import RenderPdf from "../RenderPdf";

const CasePage = () => {
  return (
    <div className="case-page">
      <RenderPdf doc={caseDoc} />
    </div>
  );
};

export default CasePage;
