import dayjs from "dayjs";
import React, { useState } from "react";
import img1 from "../../assets/img/santo1.jpeg";
import czmp from "../../assets/img/czmp.png";
import festival from "../../assets/img/festival.png";

const Events = () => {
  const [drop, setDrop] = useState(true);
  const [open, setOpen] = useState(false);
  const [eventId, setEventId] = useState(undefined);
  const events = [
    {
      img: festival,
      date: "2024-08-24",
      title: "12th Annual Community Festival",
      time: "12:30pm-7pm",
      location:
        "Northwest Regional Park",
      description:
        "Councilman Julian E. Jones Jr. is hosting the 12th Annual Community Festival",
    },
    {
      img: czmp,
      date: "2024-08-26",
      title: "CZMP District 4 Hearing",
      time: "6pm",
      location:
        "",
      description:
        "Councilman Julian E. Jones Jr. is hosting a hearing on all district 4 CZMP issues.",
    },
    {
      img: czmp,
      date: "2024-08-27",
      title: "CZMP Voting",
      time: "6pm",
      location:
        "",
      description:
        "Councilman Julian E. Jones Jr. will be deciding all CZMP District 4 issues.",
    },
    {
      img: img1,
      date: "2024-08-20",
      title: "CZMP meeting",
      time: "6pm",
      location:
        "Owings Mills Library at 10302 Grand Central Avenue in Owings Mills",
      description:
        "Boring needs you! It's our last chance to make our case for NO ML Zoming in our village!  Join your neighbors and show your support by coming to Councilman Julian Jones's  District 4 CZMP meeting.",
    },
  ];

  const trimText = (text) => {
    if (text.length > 100) {
      return text.substring(0, 100) + "...";
    }
    return text;
  };

  return (
    <section className="events-container">
      <div className="banner">
        <h1>Upcoming Events</h1>
        <button
          onClick={() => {
            setDrop(!drop);
          }}
        >
          {drop ? "Close" : "Open"} event list
        </button>
      </div>

      {drop && (
        <>
          <ul className="list">
            {events.length
              ? events.map((event, eventKey) => (
                  <li key={eventKey}>
                    <div
                      className={`img ${
                        dayjs(event.date).isBefore(dayjs())
                          ? "passed"
                          : "coming"
                      }`}
                    >
                      <img src={event.img} alt="" />
                      <span className="date">
                        {dayjs(event.date).isBefore(dayjs()) ? (
                          <p>Passed</p>
                        ) : (
                          <>
                            <p>{dayjs(event.date).format("DD")}</p>
                            <p>{dayjs(event.date).format("MMM")}</p>
                          </>
                        )}
                      </span>
                    </div>
                    <div className="content">
                      <h2>{event.title}</h2>
                      <p>
                        Date: <b>{dayjs(event.date).format("MMM DD, YYYY")}</b>,
                        at <b>{event.time}</b>
                      </p>
                      <p>{event.location}</p>
                      <p>{trimText(event.description)}</p>
                      <button
                        onClick={() => {
                          setOpen(true);
                          setEventId(eventKey);
                        }}
                      >
                        Read More
                      </button>
                    </div>
                  </li>
                ))
              : "NO EVENT"}
          </ul>
          {open && (
            <div className="popup">
              <span
                className="bg"
                onClick={() => {
                  setOpen(false);
                  setEventId(undefined);
                }}
              ></span>
              <div className="content">
                <button
                  onClick={() => {
                    setOpen(false);
                    setEventId(undefined);
                  }}
                >
                  <b>X</b>
                </button>
                <h2>{events[eventId].title}</h2>
                <p className="label">Date:</p>
                <p>
                  <b>{dayjs(events[eventId].date).format("MMM DD, YYYY")}</b>,
                  at <b>{events[eventId].time}</b>
                </p>
                <p className="label">Location:</p>
                <p>
                  <b>{events[eventId].location}</b>
                </p>
                <p className="label">Description:</p>
                <p>{events[eventId].description}</p>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Events;
