import React from "react";
import { Link } from "react-router-dom";

const TakeAction = () => {
  return (
    <div className="take-action-container">
      <div className="content">
        <h1>TAKE ACTION</h1>
        <h2>What you can do:</h2>
        <ul>
          <li>
            <p>
              Show up August 24th, and speak.{" "}
              <Link to="/news-events">See Event</Link>
            </p>
          </li>
          <li>
            <Link to="https://chng.it/7fXvTSgCPr" target="_blank">Sign the Petition</Link>
          </li>
          <li>
            <p>
              Contact Councilman Julian Jones. (Find contact infos at the bottom
              of this page)
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TakeAction;
