import React from "react";
import "../../styles/currentmission.scss";
import Gallery from "./Gallery";
import LocalGov from "./LocalGov";
import MissionLanding from "./MissionLanding";
import TakeAction from "./TakeAction";

const CurrentMissionPage = () => {
  return (
    <div className="current-mission">
      <MissionLanding />
      <TakeAction />
      <Gallery />
      <LocalGov />
    </div>
  );
};

export default CurrentMissionPage;
